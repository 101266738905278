import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../components/Bio.module.css"
import Image from "gatsby-image"
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const BlogIndex = ({ data, location }) => {
  const { author } = data.site.siteMetadata
  const siteTitle = data.site.siteMetadata.title

  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Layout location={location} title={siteTitle}>
      <h3>What Is PythonIn1Minute.com?</h3>
      <p>This is the place to learn about all things related to coding: frontend and backend development, automated
        software testing, DevOps, CI automation, programming paradigms and methodologies, career advice for software
        developers and much more.</p>
      <SEO title="About Me" />
      <div itemProp="author" itemType="http://schema.org/Person" itemScope>
        <h3>Who Runs PythonIn1Minute.com?</h3>
        <div className={styles.author}>
          <Link to="/about/" itemProp="url" className={styles.authorLink}>
            <div>
              <Image
                fixed={data.avatar.childImageSharp.fixed}
                alt={author.name}
                className={styles.imageHolder}
                imgStyle={{
                  borderRadius: `50%`,
                  marginBottom: 0,
                }}
                itemProp="image"
                loading="eager"
                critical
                placeholderStyle={{ visibility: "hidden" }}
              />
              <h4 itemProp="name" className={styles.authorName}>{author.name}</h4>
            </div>
          </Link>
          <p>
            Hello, my name is Csongor, I live in Budapest, Hungary. <br />
            I started this blog in 2020, because I'm passionate about software development and want to help out aspiring
            programmers to learn more about coding.
          </p>
        </div>
        <h3>About Me</h3>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>My Software Engineering Career In a Nutshell</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <h4>Education @ ELTE & BUTE [2004-2009]</h4>
              <Image
                fixed={data.bme.childImageSharp.fixed}
                alt="BUTE"
                itemProp="image"
              />
              <p>
                After a short (2 semester long) detour at the Physics department of <a href="https://www.elte.hu/en/"
                                                                                       target="_blank" rel="noreferrer">Eötvös
                Loránd University (ELTE)</a> I studied Computer Science and Engineering at <a href="https://www.bme.hu/"
                                                                                              target="_blank"
                                                                                              rel="noreferrer">Budapest
                University of Technology and Economics</a>. I specialized in computer vision algorithms and wrote my
                thesis
                on 3D imaging and object recognition.
              </p>
              <p>
                During my studies I also started working as a freelance web developer on some smaller projects for
                various
                local clients.
              </p>
              <h4>Full Stack Web Developer @ Webpont [2009-2017]</h4>
              <Image
                fixed={data.webpont.childImageSharp.fixed}
                alt="Webpont"
                itemProp="image"
              />
              <p>
                After graduating in 2009, I got hired as a Developer at a <a href="https://webpont.com" target="_blank"
                                                                             rel="noreferrer">Software
                Agency</a> specialized in professional sports analytics.
              </p>
              <h4>AppSec Engineer @ Emarsys [2017-2019]</h4>
              <Image
                fixed={data.emarsys.childImageSharp.fixed}
                alt="Emarsys"
                itemProp="image"
              />
              <p>
                After 8 years I moved on to the field of Information Security and I worked as an Application Security
                Engineer for the <a href="https://emarsys.com/" target="_blank" rel="noreferrer"> Emarsys automated
                marketing platform.</a> Emarsys was a huge boost to my professional development, here I learned a lot
                about
                agile methodologies,
                extreme programming, code quality and test driven development.
              </p>
              <h4>Lead Engineer @ MBA [2019-2020]</h4>
              <Image
                fixed={data.mba.childImageSharp.fixed}
                alt="Much Better Adventures"
                itemProp="image"
              />
              <p>
                In 2019, I got hired for the role of Lead Engineer at <a href="https://muchbetteradventures.com"
                                                                         target="_blank"
                                                                         rel="noreferrer">Much Better Adventures</a>. I
                am extremely
                happy that I had the opportunity to work with MBA, as their dedication towards sustainability and doing
                the
                right thing even under difficult circumstances is unmatched.
              </p>
              <h4>Freelance Software Developer @ Toptal [2020-]</h4>
              <Image
                fixed={data.toptal.childImageSharp.fixed}
                alt="Toptal"
                itemProp="image"
              />
              <p>
                Currently I work as a Freelance Full Stack Web Developer at <a
                href="https://www.toptal.com/resume/csongor-jozsa/#assume-glorious-software-freelance-contractors"
                target="_blank" rel="noreferrer">Toptal</a>. Toptal is an exclusive network of freelance software
                developers. They have a very rigorous vetting process and they accept only the top 3% of applicants.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Some Cool Open Source Projects I Was Part of</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <h4>Python Implementation of EasyCrypto</h4>
              <p>
                Easy-to-use, secure by default, open-source cryptographic library for Python. Python EasyCrypto is fully
                compatible with other EasyCrypto implementations like Node EasyCrypto. <a
                href="https://github.com/emartech/python-easy-crypto" rel="noreferrer" target="_blank">Check out on
                Github.</a>
              </p>
              <h4>Escher Auth</h4>
              <p>
                AWS-compatible request signing library. Available for Python, Go, NodeJS, Ruby, Java and PHP. <a
                href="http://escherauth.io/" rel="noreferrer" target="_blank">Check the official docs.</a>
              </p>
              <h4>Django Boilerplate</h4>
              <p>
                This is what I use to quickly bootstrap new Django projects. You can run "git clone + make start" and
                you have
                a fully dockerized Django project up and running. <a
                href="https://github.com/jcsongor/django-boilerplate" rel="noreferrer"
                target="_blank">Browse the source code on Github.</a>
              </p>
              <h4>Vanity Address Generator for Cryptocurrencies</h4>
              <p>
                The result of playing around with multiprocessing and some crypto libraries.
              </p>
              <p>
                A little script that you can use to generate an address that matches a certain pattern. Currently
                supports bitcoin only. <a href="https://github.com/jcsongor/vanity_address" rel="noreferrer"
                                          target="_blank">Github repo.</a>
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Stuff I've Built</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <h4>Tesco CE</h4>
              <p>
                I helped to design and implement a custom CMS and CRM system for Tesco Central Europe built with PHP. I
                was part of the team that helped introduce Tesco's online grocery store in the region. We've also built
                a newsletter creator tool with high volume email sending and advanced personalization capabilities.
              </p>
              <a href="https://tesco.hu" rel="noreferrer" target="_blank">Tesco Hungary</a><br />
              <a href="https://tesco.sk" rel="noreferrer" target="_blank">Tesco Slovakia</a><br />
              <a href="https://itesco.cz" rel="noreferrer" target="_blank">Tesco Czech Republic</a><br />
              <a href="https://tesco.pl" rel="noreferrer" target="_blank">Tesco Poland</a><br />
              <h4>Much Better Adventures</h4>
              <p>
                Much Better Adventures is a London-based adventure travel company that helps people organize their
                skiing,
                cycling, or road biking holiday directly with specialist independent operators. I implemented various
                features and third-party API integrations.
              </p>
              <p>
                As the lead engineer for the company, I played a key role in driving technical decisions, moving the
                system
                from a monolithic application toward a more modern microservice-based architecture, and ensuring high
                code
                quality standards.
              </p>
              <p>
                <a href="https://muchbetteradventures.com" rel="noreferrer" target="_blank">Much Better Adventures
                  Website</a>
              </p>
              <h4>Application Security Pipeline for Emarsys (Development)</h4>
              <p>
                I built an automated AppSec pipeline that automatically discovers unauthenticated endpoints along with
                all the possible query/body parameters and regularly conducts an automated vulnerability scan on these
                endpoints.
              </p>
              <h4>Authentication Solutions for the Emarsys Marketing Platform</h4>
              <p>
                As an application security engineer, I played a key role in designing and building various
                authentication/SSO solutions for the Emarsys marketing platform, working on both in-house and
                client-facing authentication services.
              </p>
              <p>
                <a href="https://emarsys.com" rel="noreferrer" target="_blank">Emarsys Website</a>
              </p>
              <h4>Forex Copy Trading Platform</h4>
              <p>
                Bootstrapped the back-end engine for a forex copy trading platform, where subscribers could follow the
                strategies of successful forex traders.
              </p>
              <h4>Data Pipeline for Professional Basketball Game Analysis</h4>
              <p>
                Developed data processing pipelines and visualization tools for sports analytics software used by
                professional sports teams.
              </p>
              <h4>Real Estate Portal (ingatlan.net)</h4>
              <p>
                As a full stack web developer, I implemented several new product features for the region's
                market-leading
                real estate portal, and I participated in building a custom CMS for the client using PHP and MySQL.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>My Preferred Tech Stack</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>
                My go-to language is <a href="https://www.python.org/" target="_blank" rel="noreferrer">Python</a>, but
                I'm also quite competent with <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                                                 target="_blank" rel="noreferrer">JavaScript</a> and <a
                href="https://www.php.net/" target="_blank" rel="noreferrer">PHP</a>.
              </p>
              <p>
                My favourite web frameworks are <a href="https://www.djangoproject.com/" target="_blank"
                                        rel="noreferrer">Django</a> and <a
                href="https://flask.palletsprojects.com/en/1.1.x/" target="_blank" rel="noreferrer">Flask</a>. On the
                frontend I prefer to work with <a href="https://reactjs.org/" target="_blank" rel="noreferrer">React</a>.
              </p>
              <p>
                This blog is built with <a href="https://www.gatsbyjs.com/" target="_blank"
                                           rel="noreferrer">Gatsby</a> and <a href="https://material-ui.com/"
                                                                              target="_blank" rel="noreferrer">Material
                UI</a> and hosted on <a href="https://www.netlify.com/" target="_blank"
                                        rel="noreferrer">Netlify</a> and <a href="https://www.cloudflare.com/"
                                                                            target="_blank"
                                                                            rel="noreferrer">Cloudflare</a>.
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Side Project: Teaching Python with Youtube Mini-Tutorials</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>
                Some things are easier to explain in a short video than in a blog post, that's why I started a youtube
                channel focusing on teaching Python for beginners.
              </p>
              <Box justifyContent="center" display="flex">
                <iframe height="500"
                        title="1 minute python"
                        src="https://www.youtube.com/embed/videoseries?list=PLR-OQ6L6UEF6CMXxp22TdfR-ZXxwMhgZe"
                        frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>What I Do Outside of Work</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>
                I am obsessed with physical activity and all forms of human movement, so if I am not at the computer,
                you can probably find me in the gym or in a park training, practicing, learning or playing.
              </p>
              <Image
                fluid={data.qdr.childImageSharp.fluid}
                alt="What I Do Outside of Work?"
                itemProp="image"
              />
              <p>
                Not bad for a 35 years old geek, huh? :-)
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Contact Me</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ marginLeft: 10 }}>
              <ul>
                <li>
                  Write an E-mail: <a href="mailto:jozsa.csongor@gmail.com" target="_blank"
                                      rel="noreferrer">jozsa.csongor@gmail.com</a>
                </li>
                <li>
                  Hire me via Toptal: <a
                  href="https://www.toptal.com/resume/csongor-jozsa/#assume-glorious-software-freelance-contractors"
                  target="_blank" rel="noreferrer">csongor-jozsa</a>
                </li>
                <li>
                  Hit me up on Skype: <a href="skype:csongor485?chat" target="_blank" rel="noreferrer">csongor485</a>
                </li>
                <li>
                  See my open source projects on GitHub: <a href="https://github.com/jcsongor" target="_blank"
                                                            rel="noreferrer">jcsongor</a>
                </li>
                <li>
                  Check my profile on LinkedIn: <a href="https://linkedin.com/in/jozsa-csongor/" target="_blank"
                                                   rel="noreferrer">jozsa-csongor</a>
                </li>
                <li>
                  You can also find me on Reddit: <a href="https://www.reddit.com/user/jcsongor" target="_blank"
                                                     rel="noreferrer">jcsongor</a>
                </li>
                <li>
                  Take a look at my Developer Story on Stackoverflow: <a
                  href="https://stackoverflow.com/users/story/8382738"
                  target="_blank" rel="noreferrer">Józsa Csongor</a>
                </li>
                <li>
                  Check out my youtube channel where I teach Python: <a
                  href="https://www.youtube.com/channel/UCjZ6DEtNXNIL1sgvuSE9gFg" target="_blank" rel="noreferrer">Python
                  In 1 Minute</a>
                </li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
        <br />
      </div>
      <br />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author {
          name
          summary
        }
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    bme: file(absolutePath: { regex: "/bme.jpg/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mba: file(absolutePath: { regex: "/mba.png/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    webpont: file(absolutePath: { regex: "/webpont.png/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    emarsys: file(absolutePath: { regex: "/emarsys.jpg/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    toptal: file(absolutePath: { regex: "/toptal.png/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    qdr: file(absolutePath: { regex: "/qdr.png/" }) {
      childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
      }
    }
  }
`
